import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag";

import "@/assets/main.scss";

createApp(App)
    .use(VueGtag, {
        appName: "Breeze Date Me",
        pageTrackerScreenviewEnabled: true,
        config: {
            id: "G-Q649DHHXT2",

        },
    }, router)
    .use(store)
    .use(router)
    .mount("#app");
